/* =================== BUSCA HEADER =================== */
jQuery(document).ready(function($) {
  // Exibe / Oculta busca
  $(".open-busca").on("click", function(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    $(".header-busca").toggleClass("active");
    if ($(".header-busca").hasClass("active")) {
      $(".header-busca #busca").focus();
    }
  });

  // Impede que a busca seja ocultada ao clicar sobre a mesma
  $(".header-busca").on("click", function(ev) {
    ev.stopPropagation();
  });

  // Oculta a busca quando clicar em qualquer parte da tela
  $("body").on("click", function(ev) {
    $(".header-busca").removeClass("active");
  });
});
